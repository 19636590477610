import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultLayout from "./DefaultLayout.js";
import { accountThunks } from "actions-thunks/account-thunks.js";
import {
  setPortalWorkflowType,
  setFieldIsEditingAC,
  setPopupStateAC,
  initializeSocketAC,
  setCurrentAccountAC,
} from "actions-thunks/actions.js";
import { documentThunks } from "actions-thunks/documents-thunks.js";
import { profileThunks } from "actions-thunks/profile-thunks.js";
import constants from "lib/constants.js";

const { portalWorkflowTypeNames } = constants;

const GetListLayoutWrapper = ({ component: Component }) => {
  const { listOfDocuments, currentDocument, pickedUpperItem, pickedChildItem } =
    useSelector((state) => state.documents);
  const { accounts, currentAccount } = useSelector((state) => state.accounts);
  const {
    profileData,
    burgerMenuOpened,
    popupState,
    fieldIsEditing,
    portalWorkflowType,
  } = useSelector((state) => state.profile);
  const { redirectItem } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(documentThunks.getListDocumentsTC());
    dispatch(profileThunks.getProfileTC());
  }, [dispatch]);

  useEffect(() => {
    if (accounts === null) {
      dispatch(accountThunks.listAccountsTC());
    }
  }, [accounts, dispatch]);

  useEffect(() => {
    if (currentAccount !== null) {
      if (localStorage.getItem("webSocketToken") === null) {
        dispatch(accountThunks.getAccountWebsocketTokenTC());
      } else {
        dispatch(initializeSocketAC());
      }
    }
  }, [currentAccount, dispatch]);

  const defineCurrentAccountToSet = (arrayOfAcc) => {
    let currentAccountToSet;
    const notFinishedOnboardingStatuses = [
      "Registered",
      "Activated",
      "Business deal",
      "Review & agreement",
    ];

    if (arrayOfAcc.length === 0) {
      return {};
    }

    if (arrayOfAcc.length === 1) {
      currentAccountToSet = arrayOfAcc[0];
    } else {
      const notSimAccounts = arrayOfAcc.filter(a => a.softwareType !== "Sim");
      const simAccount = arrayOfAcc.find(a => a.softwareType === "Sim");
      const finishedOnboardingAccountToSet = notSimAccounts.find(a => !notFinishedOnboardingStatuses.includes(a.status));

      if (finishedOnboardingAccountToSet) {
        currentAccountToSet = finishedOnboardingAccountToSet
      } else {
        currentAccountToSet = simAccount
      }
    }
    return currentAccountToSet;
  };

  const setAccountStates = async () => {
    const newAccs = accounts.map((a) => ({
      ...a,
      permissions: {
        account: true,
        document: true,
        "document.public": true,
        "account.userprofile": true,
        "account.changepassword": true,
        "account.accounts": true,
      },
    }));
    const enabledAccountStatuses = ["AccountEnabled", "AccountSuspended"];
    // currentAccountToSet is to check whether account is sim or not, and if more then 1 - pick the one that is not sim
    const currentAccountToSet = defineCurrentAccountToSet(newAccs);

    if (!!newAccs.length && !!Object.keys(currentAccountToSet).length) {
      if (newAccs.some((r) => enabledAccountStatuses.includes(r.status))) {
        dispatch(
          setPortalWorkflowType(portalWorkflowTypeNames.AccountOverview)
        );
      } else if (currentAccountToSet.softwareType === "Sim") {
        dispatch(setPortalWorkflowType(portalWorkflowTypeNames.Simulator));
      } else {
        dispatch(setPortalWorkflowType(portalWorkflowTypeNames.AccountSetup));
      }
    } else {
      dispatch(setPortalWorkflowType(portalWorkflowTypeNames.Documents));
    }

    dispatch(setCurrentAccountAC(currentAccountToSet));
  };

  useEffect(() => {
    if (accounts !== null) {
      // => if we already requested accounts and we have some - we transform the accounts data into our data structure
      setAccountStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const setPopupState = (valueToDispatch) => {
    dispatch(setPopupStateAC(valueToDispatch));
  };

  const setFieldIsEditing = (valueToDispatch) => {
    dispatch(setFieldIsEditingAC(valueToDispatch));
  };

  if (listOfDocuments !== null && currentAccount !== null && portalWorkflowType)
    return (
      <DefaultLayout
        profileData={profileData}
        redirectItem={redirectItem}
        listOfDocuments={listOfDocuments}
        portalWorkflowType={portalWorkflowType}
        pickedUpperItem={pickedUpperItem}
        pickedChildItem={pickedChildItem}
        pickedDocument={currentDocument}
        fieldIsEditing={fieldIsEditing}
        setFieldIsEditing={setFieldIsEditing}
        burgerMenuOpened={burgerMenuOpened}
        popupState={popupState}
        setPopupState={setPopupState}
        component={Component}
      />
    );
  return null;
};

export default GetListLayoutWrapper;
