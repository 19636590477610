import { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { statisticAPI } from "api/stats-api";

import {
  setBurgerMenuOpenedAC,
  setPopupStateAC,
  setRedirectItemAC,
  triggerRedirectAC,
} from "actions-thunks/actions";
import { ABOUT_ZIMTRA, TO_PROFILE } from "reducers/types";
import { authThunks } from "actions-thunks/auth-thunks";

import "./main-taskbar.scss";
import { utilityFunctions } from "ui/utilities/utilityFunctions";

const MainTaskbar = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { currentAccount } = useSelector((state) => state.accounts);
  const { loading, triggerRedirect } = useSelector((state) => state.utils);
  const { profileData, burgerMenuOpened, fieldIsEditing, profileAvatar } = useSelector(
    (state) => state.profile
  );
  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    currentAccount?._id &&
      statisticAPI.sendStatUsage(currentAccount._id, { page: `Log Out` });
    if (fieldIsEditing.status) {
      dispatch(
        setPopupStateAC({
          opened: true,
          message:
            "Are you sure you want to logout without saving the changes ?",
          type: "saves",
        })
      );
      dispatch(setRedirectItemAC({ type: "logout", item: null }));
    } else {
      dispatch(authThunks.logoutTC());
    }
  };

  useEffect(() => {
    if (triggerRedirect.status) {
      navigate(triggerRedirect.direction);
      dispatch(triggerRedirectAC({ direction: "", status: false }));
    }
  }, [triggerRedirect, navigate, dispatch]);

  const onLogoClick = () => {
    const lowerItem = {
      raction: "accounts",
      id: "editprofile",
      summary: "User profile",
      destination: TO_PROFILE,
      icon: `${process.env.PUBLIC_URL}/cabinet-account.svg`,
      permission: "account.userprofile",
    };
    if (fieldIsEditing.status) {
      dispatch(
        setPopupStateAC({
          opened: true,
          message:
            "Are you sure you want to leave without saving the changes ?",
          type: "saves",
        })
      );
      dispatch(setRedirectItemAC({ type: "lownotdoc", item: lowerItem }));
    } else {
      window.location.href = ABOUT_ZIMTRA;
    }
  };
  const onDropdownToggleClick = (e) => {
    setIsToggled(e);
  };

  const onBurgerMenuClick = () => {
    dispatch(setBurgerMenuOpenedAC(!burgerMenuOpened));
  };

  const firstname = profileData?.firstName || "";
  const lastname = profileData?.lastName || "";

  return (
    <div className="taskbar-block" data-testid="main-taskbar">
      <div className="taskbar-container">
        {isAuthenticated && (
          <img
            onClick={onBurgerMenuClick}
            className="menu-icon"
            width={24}
            height={24}
            src={`${process.env.PUBLIC_URL}/${
              burgerMenuOpened ? "x" : "menu"
            }-icon.svg`}
            alt="menu-icon"
          />
        )}
        <div className="taskbar-brand">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={fieldIsEditing.status ? "#" : `${utilityFunctions.getLandingUrl()}`}
            onClick={onLogoClick}
          >
            <img
              src={`${process.env.PUBLIC_URL}/zimtra_site_logo.png`}
              alt="zimtra-logo"
            />
            {loading && <Spinner className="spinner" animation="grow" />}
          </a>
        </div>

        <div className="taskbar-collapse-block">
          <div className="personal-icons-block">
            {/* {renderSupport()} */}
            <Dropdown
              className="dropdown-icon-block"
              onToggle={onDropdownToggleClick}
            >
              <Dropdown.Toggle
                tag={NavLink}
                className="text-nowrap px-3 dropdown-toggle-block"
              >
                <div className="taskbar-fullname-block">
                  <span>{firstname}</span>
                  <span>{lastname}</span>
                </div>
                <div className="taskbar-avatar">
                  {profileAvatar !== "/default-avatar.png" ? (
                    <div className="taskbar-avatar-picture">
                      <img src={profileAvatar} alt="Profile" />
                    </div>
                  ) : (
                    <div className="taskbar-avatar-letters">
                      {firstname[0] &&
                        `${firstname[0]}${lastname[0]}`.toUpperCase()}
                    </div>
                  )}
                  <img
                    src={`${process.env.PUBLIC_URL}/shevron-down.svg`}
                    alt="shevron-icon"
                    width="16"
                    height="16"
                    className={`dropdown-menu-icon ${
                      isToggled ? "dropdown-menu-icon-inverted" : undefined
                    }`}
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="personal-icon-dropdown-menu"
                key={"Info"}
                id={`dropdown-variants-Info`}
              >
                <Dropdown.Item onClick={onLogoutClick}>
                  <img
                    width="24"
                    height="24"
                    src={`${process.env.PUBLIC_URL}/cabinet-logout.svg`}
                    alt="logout profile"
                  />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTaskbar;
